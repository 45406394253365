import React from "react";
import ChartCard from "./ChartCard";
import Pie from "../charts/Pie";

export default class ApplianceCountPerVersionCard extends React.Component{
    render(){
        return (
            <ChartCard title="Appliance count per version">
                <Pie legend={true} resource="version-distribution" />
            </ChartCard>
        )
    }
}