import React from "react";
import PropTypes from "prop-types";
import {Bar} from "react-chartjs-2";
import ZTApi from "../../util/ZTApi"
import LoadingMessage from "../partials/LoadingMessage";
import Message from "../partials/Message"

const chartOptions = {
    legend: { display: false },
    scales: {
        yAxes: [{
            ticks: {
                display: true,
                fontColor: "rgba(255,255,255,.75)"
            },
            gridLines: {display: false}
        }],
        xAxes: [{
            ticks: {
                display: true,
                fontColor: "rgba(255,255,255,.75)"
            },
            gridLines: {display: false}
        }]
    },
    layout: {
        padding: {
            bottom:10,
            left:10,
            right:10,
        }
    }
};

class Histogram extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            loaded : false,
            chartData : undefined,
            hasData : false
        }
    }

    async refresh(){
        await this.updateState(this.props.resource)
    }

    async componentDidMount() {
        await this.updateState(this.props.resource)
    }

    async updateState(resource){
        const data = await ZTApi.get(`${resource}`);

        this.setState({
            loaded: true,
            hasData : data && data.labels && data.labels.length > 0,
            chartData : (canvas) => {
                const ctx = canvas.getContext("2d");
                const gradient = ctx.createLinearGradient(0, 0, 0, ctx.canvas.height);
                gradient.addColorStop(0, 'rgba(237,28,89,0.2)');
                gradient.addColorStop(1, 'rgba(237,28,89,.6)');

                return {
                    labels : (data.labels || []).map((str) => str.substring(0, (str + ' ').indexOf(' '))),
                    datasets : [{
                        backgroundColor : gradient,
                        hoverBackgroundColor : gradient,
                        borderColor : '#ed1c59',
                        data: data.data
                    }]
                }
            }
        })
    }

    render(){
        return (
            <div>
                {!this.state.loaded && (<LoadingMessage/>)}
                {this.state.loaded && !this.state.hasData && <Message message="No data available"/>}
                {this.state.loaded && this.state.hasData && (
                    <Bar options={chartOptions} data={this.state.chartData}/>
                )}
            </div>
        )
    }
}

Histogram.propTypes = {
    resource : PropTypes.string
};

export default Histogram;