import React from "react";
import PropTypes from "prop-types";
import {Input} from "reactstrap"
class ApplianceStatusDropdown extends React.Component{
    render(){
        return (
            <div className="inline-select-container">
                <Input className="inline-select" type="select" onChange={(e) => {
                    this.props.onChange(e.target.value)
                }}>
                    <option value="registeredAppliance">registered</option>
                    <option value="completedAppliance">completed</option>
                    <option value="revertedAppliance">reverted</option>
                    <option value="ignoreAppliance">ignored</option>
                </Input>
            </div>);
    }
}

ApplianceStatusDropdown.propTypes = {
    onChange : PropTypes.func.isRequired
};

export default ApplianceStatusDropdown;