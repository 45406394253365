import React from "react";
import ChartCard from "./ChartCard";
import Histogram from "../charts/Histogram";

export default class ApplianceCountPerModelCard extends React.Component{
    render(){
        return (
            <ChartCard title="Appliance count per model">
                <Histogram resource="appliances-per-model" />
            </ChartCard>
        )
    }
}