import React from "react";
import ChartCard from "./ChartCard";
import Pie from "../charts/Pie";

export default class ConfigurationMatcherDistributionCard extends React.Component{
    render(){
        return (
            <ChartCard title="Configuration matcher distribution">
                <Pie resource="matcher-distribution" />
            </ChartCard>
        )
    }
}