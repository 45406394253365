import "dotenv/config"
const apiUrl = "https://api.cohabitate.app/stats";
console.log("api url is", apiUrl);

let fetching = false;
let portalAccount = '';
const pendingCalls = [];

let process;
process = async () => {
    if(fetching || pendingCalls.length <= 0) return;
    fetching = true;

    const call = pendingCalls[0];
    call.data = Object.assign(call.data, {portalAccount : portalAccount});

    let dataUrl = '';
    for(const i in call.data){
        dataUrl += i + "=" + call.data[i] + "&";
    }

    const res = await fetch(`${apiUrl}/${pendingCalls[0].url}?${dataUrl}`);
    try{
        const json = await res.json();
        if(pendingCalls[0]) pendingCalls[0].cb(json);
    } catch{
        if(pendingCalls[0]) pendingCalls[0].cb();
    }

    fetching = false;
    if(pendingCalls[0]) {
        pendingCalls.shift();
        process()
    }
};

const ZTApi = {
    clearQueue : () => {
        while(pendingCalls.length)
            pendingCalls.pop()
    },

    setPortalAccount : (pa) => {
        portalAccount = pa;
    },

    get : async(url, data = {}) =>  {
        return new Promise((res) => {
            pendingCalls.push({url: url, cb: res, data: data})
            process();
        })
    },
};

export default ZTApi