import React from "react";
import ChartCard from "./ChartCard";
import LineChart from "../charts/Line";

export default class NewCustomersPerMonthCard extends React.Component{
    render(){
        return (
            <ChartCard title="New customers per month">
                <LineChart resource="new-customers"/>
            </ChartCard>
        )
    }
}