import React from "react";
import ChartCard from "./ChartCard";
import Line from "../charts/Line";

export default class AuditLogEntriesPerMonthCard extends React.Component{
    render(){
        return (
            <ChartCard title="Audit log entries per month">
                <Line resource="audit-log-entries" />
            </ChartCard>
        )
    }
}