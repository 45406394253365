import React from 'react';
import {Button, Table} from 'reactstrap';
import ZTApi from "../../util/ZTApi";
import LoadingMessage from "./LoadingMessage";
import {Link} from "react-router-dom";
import Message from "./Message";

export default class CustomersTable extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            data : [],
            start : 0,
            finished : false,
            loaded : false
        }
    }

    async load(){
        const data = []//await ZTApi.get(`top-customers`, {start:this.state.start}) || [];
        this.setState({
            data: [...this.state.data, ...data],
            start : this.state.start + 5,
            finished : data.length <= 0,
            loaded : true
        });
    }


    async componentDidMount(){
        await this.load()
    }

    render() {
        return (
            <div>
                {!this.state.loaded && (<LoadingMessage/>)}
                {this.state.loaded && (
                    <div>
                        {this.state.data.length > 0 ? (
                            <div>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th width="16%">Portal ID</th>
                                            <th>Name</th>
                                            <th>Appliances</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {this.state.data.map((customer) => (
                                            <tr key={customer.portalId}>
                                                <th scope="row">
                                                    <Link to={`portal-account/${customer.portalId}`}>{customer.portalId}</Link>
                                                </th>
                                                <td>{customer.name}</td>
                                                <td>{customer.applianceCount}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>


                                <div className="load-more-container">
                                    {!this.state.finished && (
                                        <Button className="load-more" onClick={() => {
                                            this.load()
                                        }}>
                                            Load more
                                        </Button>
                                    )}
                                </div>
                            </div>
                        ) : <Message message="No data available"/>}
                    </div>
                )}
            </div>
        );
    }
}