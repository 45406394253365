import React from "react";
import {Card, CardBody, CardHeader} from "reactstrap";
import Pie from "../charts/Pie"
import StatusCallDropdown from "../partials/StatusCallDropdown";

class StatusCallDistributionCard extends React.Component{
    constructor(props){
        super(props);
        this.pieChart = React.createRef();

        this.state = {
            currentResource : "all"
        }
    }

    refresh(){
        this.pieChart.refresh()
    }

    render(){

        return (
            <Card>
                <CardHeader>
                    Call Status for
                    <StatusCallDropdown
                        onChange={resource => {

                            this.setState({currentResource: resource});
                            this.pieChart.updateData(`status-call-distribution/${resource}`) }}
                    />
                    event{this.state.currentResource === "all" ? "s" : ""}
                </CardHeader>
                <CardBody>
                    <Pie resource="status-call-distribution/all" ref={ref => { this.pieChart = ref }} legend={true}/>
                </CardBody>
            </Card>
        )
    }
}

export default StatusCallDistributionCard;