import React from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  InputGroup} from 'reactstrap';
import CudaLogo from "../partials/CudaLogo";
import {Link, withRouter} from "react-router-dom";


export class Header extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };

    this.portalAccountInput = React.createRef();
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  render() {
    return (
      <div>
        <Navbar expand="md">
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>

              <Nav navbar>
                  <NavItem>
                      <Link className="nav-link" to="/">Dashboard</Link>
                  </NavItem>
                  <NavItem>
                      <Link className="nav-link" to="/system">System statistics</Link>
                  </NavItem>
                  <NavItem>
                      <Link className="nav-link" to="/display">Display</Link>
                  </NavItem>
              </Nav>


              <Nav className="ml-auto" navbar>
                  <NavItem>
                      <form onSubmit={(e) => {
                          this.props.history.push(`/portal-account/${this.portalAccountInput.current.value}`);
                          e.preventDefault();
                      }}>
                        <InputGroup>
                            <input className="form-control" name="portal-account" ref={this.portalAccountInput} placeholder="Search portal account..." />
                        </InputGroup>
                      </form>
                  </NavItem>
              </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

export default withRouter(Header)