import React from "react"
import Message from "./Message";

class LoadingMessage extends React.Component{
    render(){
        return (
            <Message message="Loading..." />
        )
    }
}

export default LoadingMessage;