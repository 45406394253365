import React from 'react';
import {Col, Row, Container} from 'reactstrap';

import ZTApi from "../../util/ZTApi";
import StatCards from "../cards/stats/UserChoreCards";
import PageTitle from "../elements/PageTitle";
import GenericPage from "./Generic";

import {
ApplianceCountPerModelCard,
ApplianceStatusCard,
NewCustomersPerMonthCard,
TopCustomerDistributionCard
} from "../cards/Cards"

class Home extends GenericPage{
    constructor(){
        super();
        ZTApi.setPortalAccount('');
    }

    render() {
        return (
            <Container className="wide">
                <PageTitle title="Zero Touch Statistics"/>
                <Row>
                    <Col lg="12">
                        <StatCards/>
                    </Col>
                </Row>

                <Row>
                    <Col lg="6" className="stat-card-container">
                        <ApplianceStatusCard />
                    </Col>

                    <Col lg="6" className="stat-card-container">
                        <NewCustomersPerMonthCard/>
                    </Col>

                    <Col lg="6" className="stat-card-container">
                        <TopCustomerDistributionCard/>
                    </Col>

                    <Col lg="6" className="stat-card-container">
                        <ApplianceCountPerModelCard/>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default Home