import React from "react"
import {Col, Row} from "reactstrap";
import StatCard from "./StatCard";
import {Animated} from "react-animated-css";
import TextLoop from "react-text-loop";
import {StatCardsGeneric} from "./StatCardsGeneric";
import {FaUserPlus, FaUsers} from "react-icons/fa";
import {GoFlame, GoServer} from "react-icons/go";

class UserChoreCards extends StatCardsGeneric{
    constructor(props){
        super(props);
        this.api = "users-chores-counts";
    }

    render(){
        const {
            totalUsers = 0,
            newUsersDay = 0,
            newUsersWeek = 0,
            newUsersMonth = 0,
            newUsersYear = 0,

            totalChores = 0,
            newChoresDay = 0,
            newChoresWeek = 0,
            newChoresMonth = 0,
            newChoresYear = 0,
        } = this.state.statistics || {};

        return (
            <Row>
                <Col xs="3">
                    <Animated animationIn="fadeInLeft">
                        <StatCard icon={<FaUsers/>} label={<span>Total users</span>} value={'' + totalUsers}/>
                    </Animated>
                </Col>

                <Col xs="3">
                    <Animated animationIn="fadeIn">
                        <StatCard
                            icon={<FaUserPlus/>}
                            iconClass="accent"
                            label={
                                <span>
                                    New users (<TextLoop interval={this.cycleInterval} children={["day", "week", "month", "year"]}/>)
                                </span>
                            }
                            value={
                                <TextLoop interval={this.cycleInterval}>
                                    <span>{newUsersDay}</span>
                                    <span>{newUsersWeek}</span>
                                    <span>{newUsersMonth}</span>
                                    <span>{newUsersYear}</span>
                                </TextLoop>
                            }
                        />
                    </Animated>
                </Col>

                <Col xs="3">
                    <Animated animationIn="fadeIn">
                        <StatCard icon={<GoServer/>} label="Total chores" value={totalChores}/>
                    </Animated>
                </Col>

                <Col xs="3">
                    <Animated animationIn="fadeInRight">
                        <StatCard
                            icon={<GoFlame/>}
                            // iconClass=""
                            label={
                                <span>
                                    New chores (<TextLoop interval={this.cycleInterval} children={["day", "week", "month", "year"]}/>)
                                </span>
                            }
                            value={
                                <TextLoop interval={this.cycleInterval}>
                                    <span>{newChoresDay}</span>
                                    <span>{newChoresWeek}</span>
                                    <span>{newChoresMonth}</span>
                                    <span>{newChoresYear}</span>
                                </TextLoop>
                            }
                        />
                    </Animated>
                </Col>
            </Row>
        );
    }
}

export default UserChoreCards;