import React from "react";
import PropTypes from "prop-types";
import {Line as LineGraph} from "react-chartjs-2";
import ZTApi from "../../util/ZTApi"
import Message from "../partials/Message";
import LoadingMessage from "../partials/LoadingMessage"
import Generic from "./Generic";

const chartOptions = {
    legend: { display: false },
    scales: {
        yAxes: [{
            ticks: {
                display: true,
                fontColor: "rgba(255,255,255,.75)",
                precision: 0

            },
            gridLines: {display: false}
        }],
        xAxes: [{
            ticks: {
                display: true,
                fontColor: "rgba(255,255,255,.75)"
            },
            gridLines: {display: false}
        }]
    },
    layout: {
        padding: {
            bottom:10,
            left:10,
            right:10,
        }
    }
};

class Line extends Generic {
    async updateState(resource){
        const colors = [ {r:0, g:227, b:163}, {r:214,g:133,b:15}, {r:48, g:247, b:220}, {r:36, g: 15, b:214}, {r: 181, g: 219, b:52}];

        const datas = [];
        const data = await ZTApi.get(`${resource}`);




        console.log("DATAS ARE", data);
        console.log("data labels",       data.labels);
        this.setState({
            loaded: true,
            hasData : data && data.labels && data.labels.length > 0,
            chartData : (canvas) => {
                const ctx = canvas.getContext("2d");
                const datasets = [];

                console.log("chart data...");

                
                    const c = colors[0];
                    const gradient = ctx.createLinearGradient(0, 0, 0, ctx.canvas.height);
                    gradient.addColorStop(0, `rgba(${c.r},${c.g},${c.b},0.2)`);
                    gradient.addColorStop(0, `rgba(${c.r},${c.g},${c.b},0.05)`);

                    datasets.push({
                        backgroundColor : gradient,
                        borderColor : `rgba(${c.r},${c.g},${c.b},1)`,
                        data: data.values,
                        label: data.label,
                    });

                    console.log("datasets", datasets);

                    console.log("dataset", datasets);
                return {
                    labels : data.labels,
                    datasets : datasets
                }
            }
        });
    }

    async updateData(resource){
        this.setState({
            resource: resource
        });

        await this.updateState(resource)
    }

    async componentDidMount(){
        await this.updateState(this.props.resource);
    }

    render(){
        return (
            <div>
                {this.state.loaded && !this.state.hasData && <Message message="No data available"/>}
                {!this.state.loaded && (
                    <LoadingMessage/>
                )}
                {this.state.loaded && this.state.hasData && (
                    <LineGraph options={chartOptions} data={this.state.chartData}/>)}
            </div>
        )
    }
}

Line.propTypes = {
    resource : PropTypes.string
};

export default Line;