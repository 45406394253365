import React from "react";
import PropTypes from "prop-types";
import ZTApi from "../../util/ZTApi"

class GenericChart extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            loaded : false,
            hasData : false,
            chartData : undefined
        }
    }

    async refresh(){
        await this.updateState(this.props.resource)
    }

    async componentDidMount() {
        await this.updateState(this.props.resource)
    }

    async updateGraph(data){
    }

    async updateState(resource = this.props.resource){
        const data = await ZTApi.get(resource);
        await this.updateGraph(data)
    }

    async updateData(){
        const data = await ZTApi.get(`${this.props.resource}`);
        await this.updateGraph(data)
    }

    render(){
        const {children} = this.props;
        return (
            <div>
                {children}
            </div>
        )
    }
}

GenericChart.propTypes = {
    resource : PropTypes.string
};

export default GenericChart;