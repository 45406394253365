import React from "react"
class CudaLogo extends React.Component{
    render(){
        return (
            <svg
                width="56"
                height="50"
                className="fmaas-footer-logo"
                viewBox="0 0 135 120"
                preserveAspectRatio="xMinYMin slice"
            >
                <path style={{fill : "#0088ce"}}
                      d="M26.24,98.62H0C0,57,11,30.56,11,30.56,7.54,75.34,26.24,98.62,26.24,98.62ZM134.95,0S51.64,32.9,36.19,98.62h70.13S56,85.43,134.95,0Z"/>
            </svg>
            )
    }
}

export default CudaLogo;