import React from "react";
import PropTypes from "prop-types";
import {Card, CardBody, CardHeader} from "reactstrap";
import {Animated} from "react-animated-css"

class ChartCard extends React.Component{
	render(){
		const {
			title,
			children
		} = this.props;

		return (
            <Animated animationIn="fadeIn">
                <Card>
                    <CardHeader>{title}</CardHeader>
                    <CardBody>
                        {children}
                    </CardBody>
                </Card>
			</Animated>
		)
	}
}

ChartCard.propTypes = {
	children : PropTypes.element.isRequired,
	title : PropTypes.string.isRequired
}

export default ChartCard;