import React from "react";
import PropTypes from "prop-types";
import {Input} from "reactstrap"

class StatusCallDropdown extends React.Component{
    render(){
        return (
            <div className="inline-select-container">
                <Input className="inline-select" type="select" onChange={(e) => {
                    this.props.onChange(e.target.value)
                }}>
                    <option value="all">all</option>
                    <option value="authenticationAttempt">authentication attempt</option>
                    <option value="claimAppliance">claimed appliance</option>
                    <option value="completedAppliance">appliance completed</option>
                    <option value="createConfiguration">configuration created</option>
                    <option value="createNotification">notification created</option>
                    <option value="deleteNotification">notification deleted</option>
                    <option value="createPortalAccount">portal account created</option>
                    <option value="deleteConfiguration">configuration deleted</option>
                    <option value="ignoreAppliance">appliance ignored</option>
                    <option value="registeredAppliance">appliance registered</option>
                    <option value="revertedAppliance">appliance reverted</option>
                    <option value="revokeAccessToken">access token revoked</option>
                    <option value="updateApplianceAction">appliance updated</option>
                    <option value="synchronizeAppliances">appliance synchronized</option>
                    <option value="assignConfigurationToAppliance">configuration assigned to appliance</option>
                    <option value="unassignConfigurationFromAppliance">configuration unassigned from appliance</option>
                    <option value="updateApplianceMissingConfiguration">appliance missing configuration updated</option>
                    <option value="updateApplianceSignatureFailure">appliance signature failure</option>
                    <option value="updateApplianceSslFailure">appliance SSL failure</option>
                    <option value="updateConfiguration">configuration updated</option>
                    <option value="updateNotification">notification updated</option>
                    <option value="updateSetting">settings updated</option>
                </Input>
            </div>
        );
    }
}

StatusCallDropdown.propTypes = {
    onChange : PropTypes.func.isRequired
};

export default StatusCallDropdown;