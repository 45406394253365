import React from 'react';
import { Col, Row, Container} from 'reactstrap';
import ZTApi from "../../util/ZTApi";
import PageTitle from "../elements/PageTitle";
import {
    ApplianceCountPerModelCard,
    ApplianceCountPerVersionCard,
    AppliancesAddedPerMonthCard,
    ConfigurationMatcherDistributionCard,
    CustomersTableCard,
    EntryTypeDistributionCard,
    AuditLogEntriesPerMonthCard,
    EventTypeDistributionCard,
    StatusCallDistributionCard,
    StatusDistributionCard,
} from "../cards/Cards"
import GenericPage from "./Generic";

export class System extends GenericPage{
    constructor(){
        super();
        ZTApi.setPortalAccount('');
    }

    render() {
        return (
            <Container className="page wide">
                <PageTitle title="Zero Touch System Statistics"/>

                <Row>
                    <Col lg="4" className="stat-card-container">
                        <EntryTypeDistributionCard/>
                    </Col>

                    <Col lg="4" className="stat-card-container">
                        <EventTypeDistributionCard/>
                    </Col>

                    <Col lg="4" className="stat-card-container">
                        <StatusCallDistributionCard/>
                    </Col>

                    <Col lg="5">
                        <Row>
                            <Col xs="12" className="stat-card-container">
                                    <CustomersTableCard/>
                            </Col>

                            <Col xs="12" className="stat-card-container">
                                <AuditLogEntriesPerMonthCard/>
                            </Col>


                            <Col xs="12" className="stat-card-container">
                                <ApplianceCountPerModelCard/>
                            </Col>
                        </Row>
                    </Col>

                    <Col lg="7">
                        <Row>
                            <Col lg="12" className="stat-card-container">
                                <AppliancesAddedPerMonthCard/>
                            </Col>

                            <Col lg="6" className="stat-card-container">
                                <StatusDistributionCard/>
                            </Col>

                            <Col lg="6" className="stat-card-container">
                                <ConfigurationMatcherDistributionCard/>
                            </Col>

                            <Col lg="12" className="stat-card-container">
                                <ApplianceCountPerVersionCard/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default System