import React from 'react';
import { Col, Container, Row} from 'reactstrap';
import ZTApi from "../../util/ZTApi";
import PageTitle from "../elements/PageTitle";
import Message from "../partials/Message";
import {
    ApplianceCountPerModelCard,
    ApplianceStatusCard,
    ApplianceVersionDistributionCard,
    AuditLogEntriesPerMonthCard,
    ConfigurationMatcherDistributionCard,
    EntryTypeDistributionCard,
    EventTypeDistributionCard,
    StatusCallDistributionCard,
    StatusDistributionCard
} from "../cards/Cards"
import GenericPage from "./Generic";

class PortalAccount extends GenericPage{
    async componentDidMount(){
        const {account} = this.props.match.params;
        ZTApi.setPortalAccount(account);

        const customer = undefined;//await ZTApi.get(`portal-account/${account}`);

        this.setState({
            customer : customer,
            loaded : true
        })
    }

    constructor(){
        super();
        this.state = {
            customer : {
                name: "Unknown",
                portal_id : 0,
                loaded : false
            }
        }

    }

    render() {
        const {
            name = 'Unknown',
        } = this.state.customer || {};

        return (
            (name !== 'Unknown') ? (
                <Container>
                    <PageTitle title={`${name} Customer Statistics`}/>

                    <Row>
                        <Col lg="6" className="stat-card-container">
                            <ApplianceStatusCard/>
                        </Col>

                        <Col lg="6" className="stat-card-container">
                            <StatusDistributionCard/>
                        </Col>

                        <Col lg="6" className="stat-card-container">
                            <ApplianceCountPerModelCard/>
                        </Col>

                        <Col lg="6" className="stat-card-container">
                            <AuditLogEntriesPerMonthCard/>
                        </Col>

                        <Col lg="4" className="stat-card-container">
                            <ApplianceVersionDistributionCard/>
                        </Col>

                        <Col lg="4" className="stat-card-container">
                               <ConfigurationMatcherDistributionCard/>
                        </Col>

                        <Col lg="4" className="stat-card-container">
                            <EntryTypeDistributionCard/>
                        </Col>

                        <Col lg="4" className="stat-card-container">
                            <EventTypeDistributionCard/>
                        </Col>

                        <Col lg="4" className="stat-card-container">
                            <StatusCallDistributionCard/>
                        </Col>
                    </Row>

                </Container>
            ) : (
                <div>
                    {this.state.loaded && (<Message message="Portal account not found"/>)}
                </div>
            )
        );
    }
}

export default PortalAccount