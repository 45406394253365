import React from "react";
import {Card, CardBody, CardHeader, Col} from "reactstrap";
import CustomersTable from "../partials/CustomersTable";

export default class CustomersTableCard extends React.Component{
    render(){
        return (
            <Card>
                <CardHeader>Customers</CardHeader>
                <CardBody>
                    <Col lg="12">
                        <CustomersTable />
                    </Col>
                </CardBody>
            </Card>
        )
    }
}