import React from "react";
import {Card, CardBody, CardHeader} from "reactstrap";
import ApplianceStatusDropdown from "../partials/ApplianceStatusDropdown";
import Line from "../charts/Line"

export default class ApplianceStatusCard extends React.Component {
    constructor(props) {
        super(props);
        this.lineGraph = React.createRef();
    }

    render() {
        return (
            <Card>
                <CardHeader>
                    Appliances
                    <ApplianceStatusDropdown
                        onChange={resource => {
                            this.lineGraph.updateData(`appliance-status-count/${resource}`);
                        }}
                    />
                    per month
                </CardHeader>

                <CardBody>
                    <Line resource="appliance-status-count/registeredAppliance" ref={ref => {
                        this.lineGraph = ref
                    }}/>
                </CardBody>
            </Card>
        )
    }
}