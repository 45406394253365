import React from "react";
import ZTApi from "../../../util/ZTApi";

export class StatCardsGeneric extends React.Component{
    constructor(props){
        super(props);

        this.cycleInterval = 6000;
        this.refreshInterval = 60000;
        this.state = {
            statistics : {}
        }
    }

    async getData(){
        if(!this.api) return;
        const data = await ZTApi.get(this.api);
        if(data && Object.keys(data).length > 0){
            Object.keys(data).map((k) => {
                return data[k] = Number(data[k]).toLocaleString();
            });
        }

        this.setState({
            statistics: data
        });
    }

    async componentDidMount(){
        await this.getData();

        let runTimeout = (() => {
            this.refreshTimeout = setTimeout(async () => {
                await this.getData();
                runTimeout()
            }, this.refreshInterval)
        });

        runTimeout();
    }

    async componentWillUnmount(){
        clearTimeout(this.refreshTimeout)
    }

}
