import React from "react";
import {Col, Row} from "reactstrap";
import PropTypes from "prop-types";

export class PageTitle extends React.Component{
    render(){
        const {title} = this.props;
        return (
            <Row>
                <Col sm="12">
                    <div className="page-header">
                        <h1>{title}</h1>
                    </div>
                </Col>
            </Row>
        );
    }
}

PageTitle.propTypes = {
    title : PropTypes.string.isRequired
};

export default PageTitle;