import React from "react";
import PropTypes from "prop-types";
import {Pie as ChartPie} from "react-chartjs-2";
import LoadingMessage from "../partials/LoadingMessage";
import Generic from "./Generic";
import Message from "../partials/Message";

const colors = [
    {r : 237, g: 28, b: 89},
    {r : 237*0.75, g: 28*0.75, b: 89*0.75},
    {r : 237*0.50, g: 28*0.50, b: 89*0.50},
    {r : 237*0.65, g: 28*0.65, b: 89*0.65},
    {r : 237*0.85, g: 28*0.85, b: 89*0.85},
    {r : 237*0.90, g: 28*0.90, b: 89*0.90},
    {r : 237*0.45, g: 28*0.45, b: 89*0.45},
];

const getChartOptions = (legend) => {
    return {
        legend: {display: legend, position: 'bottom'},
        scales: {
            yAxes: [{
                ticks: {
                    display: false,
                },
                gridLines: {display: false}
            }],
            xAxes: [{
                ticks: {
                    display: false,
                },
                gridLines: {display: false}
            }]
        },
        plugins: {
            labels: {
                render: 'label'
            }
        },
        layout: {
            padding: {
                bottom: 10,
                left: 10,
                right: 10,
            }
        },
    }
};

export class Pie extends Generic {
    async updateGraph(data){
        data = data && data.data ? data : {labels: [], data: []};
        data.labels = data.labels.map((label) => {
            return label.charAt(0).toUpperCase() + label.substr(1)
        });

        const getBackgroundColor = ((k) => {
            const shade = (Math.random()/2)+0.5;
            const color = colors[k] || {r: Math.floor(shade * 237), g: Math.floor(shade * 28), b: Math.floor(shade * 89)};
            return `rgba(${color.r}, ${color.g}, ${color.b}, 0.7)`
        });

        this.setState({
            hasData : data.labels.length > 0,
            loaded: true,
            chartData : () => {
                return {
                    labels : data.labels,
                    datasets : [{
                        borderColor : 'transparent',
                        data: data.data,
                        hoverBackgroundColor:  data.data.map((entry, k) => getBackgroundColor(k)),
                        backgroundColor: data.data.map((entry, k) => getBackgroundColor(k))
                    }]
                }
            }
        })
    }

    async updateData(resource){
        this.setState({
            resource: resource
        });

        await this.updateState(resource)
    }


    render(){
        const {
            legend
        } = this.props;

        return (
            <div className="centered-vertically">
                {this.state.loaded && !this.state.hasData && <Message message="No data available"/>}

                {!this.state.loaded && (
                    <LoadingMessage/>
                )}

                {this.state.loaded && this.state.hasData && (
                    <ChartPie options={getChartOptions(legend)} data={this.state.chartData}/>
                )}
            </div>
        )
    }
}

Pie.propTypes = {
    resource : PropTypes.string,
    legend : PropTypes.bool
};

Pie.defaultProps = {
    legend: false
};

export default Pie;