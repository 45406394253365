import React from "react"
import PropTypes from "prop-types";
import {Animated} from "react-animated-css"
class Message extends React.Component{
    render(){
        return (
            <Animated animationIn="bounceIn">
                <h1 className="card-message">{this.props.message}</h1>
            </Animated>
        )
    }
}

Message.propTypes = {
    message: PropTypes.string
};

export default Message;