import React from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import LineChart from "../charts/Line";
import Histogram from "../charts/Histogram";
import ZTApi from "../../util/ZTApi";
import StatCards from "../cards/stats/UserChoreCards";
import TextLoop from "react-text-loop";
import Line from "../charts/Line"
import ConfigurationAuditLogCards from "../cards/stats/ConfigurationAuditLogCards";
import GenericPage from "./Generic";
import PageTitle from "../elements/PageTitle";

class Display extends GenericPage{
    constructor(){
        super();

        ZTApi.setPortalAccount('');
        this.statusCalls = {
            registered : 'registeredAppliance',
            completed : 'completedAppliance',
            reverted : 'revertedAppliance',
            ignored : 'ignoreAppliance'
        }
    }

    render() {
        return (
            <div className="container wide">
                <div className="logo-container">
                <img src="./logo.png" className="logo"/>
                </div>

                <Row>
                    <Col lg="12">
                        <StatCards/>
                    </Col>

                </Row>

                <Row>
                    <Col xs="6" className="stat-card-container display-graph">
                        <Card>
                            <CardHeader>
                                New users per day
                            </CardHeader>
                            <CardBody>
                                <Line resource="new-users-per-day" ref={ref => { this.statusChart = ref }}/>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col xs="6" className="stat-card-container display-graph">
                        <Card>
                            <CardHeader>
                                  Chores completed per day
                            </CardHeader>
                            <CardBody>
                            <Line resource="new-chores-per-day" ref={ref => { this.statusChart = ref }}/>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>

               

            </div>
        );
    }
}

export default Display