import React, { Component } from 'react';
import Header from "./components/elements/Header"
import Main from "./components/Main"

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

class App extends Component {
  render() {
    return (
      <div>
        <Main/>
      </div>
    );
  }
}

export default App;