import React from "react";
import ChartCard from "./ChartCard";
import Pie from "../charts/Pie";

export default class EntryTypeDistributionCard extends React.Component{
    render(){
        return (
            <ChartCard title="Entry type distribution">
                <Pie resource="entry-distribution" />
            </ChartCard>
        )
    }
}