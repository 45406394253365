import React from "react";
import {Card, CardBody, Col, Row} from "reactstrap";
import PropTypes from "prop-types";

class StatCard extends React.Component{
    render(){
        return (
            <Card className="small-stat-card">
                <CardBody>
                    <Row>
                        <Col xs="4" className="icon-card">
                            <h1 className={this.props.iconClass}>{this.props.icon}</h1>
                        </Col>
                        <Col>
                            <h3>{this.props.value}</h3>
                            <h6>{this.props.label}</h6>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        )
    }
}

StatCard.propTypes = {
    label : PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    value : PropTypes.any.isRequired,
    icon : PropTypes.element.isRequired,
    iconClass : PropTypes.string
};

StatCard.defaultProps = {
    iconClass : ""
};

export default StatCard;