import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'
import Home from "./pages/Home"
import PortalAccount from "./pages/PortalAccount"
import System from "./pages/System";
import Display from "./pages/Display";
class Main extends Component{
    render(){
        return (
            <main>
                <Switch>
                    <Route exact path='/' component={Home}/>
                    <Route exact path='/system' component={System}/>
                    <Route exact path='/display' component={Display}/>
                    <Route path='/portal-account/:account' component={PortalAccount}/>
                </Switch>
            </main>
        )
    }
}

export default Main
